import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Stack, Paper, Avatar, Button } from '@mui/material';
import { styled } from '@mui/material';

import logo from './Assets/logo.png'
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));


const App = () => {
    return (
        <div>
            <Stack direction="column" spacing={2} sx={{
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Item>
                    <Avatar
                        alt="Tailored Fit"
                        src={logo}
                        sx={{ width: 56, height: 56 }}
                    />
                </Item>
                <Item>The Tailored Fit tools are moved</Item>
                <Item>
                    <Button href="https://tools.tailoredfit.ai">
                        Clic here to access to the new version
                    </Button>
                </Item>
            </Stack>
        </div>
    );
};


export default App;